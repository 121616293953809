/**
 * Seeq REST API
 * No description provided (generated by Swagger Codegen https://github.com/swagger-api/swagger-codegen)
 *
 * OpenAPI spec version: 61.1.4-v202308011800
 * 
 *
 * NOTE: This class is auto generated by the swagger code generator program.
 * https://github.com/swagger-api/swagger-codegen.git
 * Do not edit the class manually.
 */
import { AxiosPromise } from 'axios';
import { getAxiosInstance, SeeqAxiosRequestConfig } from '@/requests/axios.utilities';
import { isNil, omitBy } from 'lodash';
import * as qs from 'qs';
import * as models from '../model/models';
import { APPSERVER_API_PREFIX } from '@/main/app.constants';

export class sqRequestsApiClass {
 
   constructor() {}

  /**
   * @summary Cancel all requests
   */
  public cancelAllRequests(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/requests/all`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Cancel all requests made by the invoking user
   */
  public cancelMyRequests(
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/requests/me`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Cancel all requests made by the invoking user for a specific session
   * @param {string} sessionId - ID of the current session
   */
  public cancelMyRequestsBySessionId(
    {
      sessionId
    } : {
      sessionId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(sessionId)) {
      throw new Error("'sessionId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/requests/me/${encodeURIComponent(String(sessionId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Cancel a request
   * @param {string} requestId - ID of the request to cancel
   */
  public cancelRequest(
    {
      requestId
    } : {
      requestId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(requestId)) {
      throw new Error("'requestId' parameter required");
    }

    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/requests/${encodeURIComponent(String(requestId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Cancel multiple requests, optionally filtering by a user and/or datasource. If you don't specify any filters, all of the requests currently running in Seeq will be cancelled. If you specify multiple filters, only requests matching all filters will be cancelled.
   * @param {string} [userId] - userId
   * @param {string} [datasourceClass] - datasourceClass
   * @param {string} [datasourceId] - datasourceId
   * @param {string} [dataId] - dataId
   */
  public cancelRequests(
    {
      userId,
      datasourceClass,
      datasourceId,
      dataId
    } : {
      userId?: string,
      datasourceClass?: string,
      datasourceId?: string,
      dataId?: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'delete',
      url: APPSERVER_API_PREFIX + `/requests`,

      params: omitBy({
        ['userId']: userId,
        ['datasourceClass']: datasourceClass,
        ['datasourceId']: datasourceId,
        ['dataId']: dataId
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

  /**
   * @summary Get progress information for the request identified by the supplied ID
   * @param {string} requestId - ID of the request for which to retrieve progress information
   */
  public getRequest(
    {
      requestId
    } : {
      requestId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(requestId)) {
      throw new Error("'requestId' parameter required");
    }

    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/requests/${encodeURIComponent(String(requestId))}`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.GetRequestOutputV1>;
  }

  /**
   * @summary Get the collection of requests
   * @param {number} [limit=250] - The maximum number of requests that will be returned
   */
  public getRequests(
    {
      limit
    } : {
      limit?: number
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    return getAxiosInstance()({
      method: 'get',
      url: APPSERVER_API_PREFIX + `/requests`,

      params: omitBy({
        ['limit']: limit
      }, isNil),
      paramsSerializer: params => qs.stringify(params, { indices: false }),
      ...extraHttpRequestConfig
      }) as AxiosPromise<models.GetRequestsOutputV1>;
  }

  /**
   * @summary Restart a request, sending it to the back of the line
   * @param {string} requestId - ID of the request to restart
   */
  public restartRequest(
    {
      requestId
    } : {
      requestId: string
    },
    extraHttpRequestConfig?: SeeqAxiosRequestConfig
  ) {
    if (isNil(requestId)) {
      throw new Error("'requestId' parameter required");
    }

    return getAxiosInstance()({
      method: 'post',
      url: APPSERVER_API_PREFIX + `/requests/${encodeURIComponent(String(requestId))}/restart`,

      ...extraHttpRequestConfig
      }) as AxiosPromise<models.StatusMessageBase>;
  }

}


export const sqRequestsApi = new sqRequestsApiClass();
